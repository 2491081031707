<template>
  <v-popover offset="10"
             :placement="popoverPlacement"
             trigger="hover"
             popoverClass="dashboard_popover"
             popoverWrapperClass="dashboard_popover_wrapper"
             popoverInnerClass="dashboard_popover_content"
             boundariesElement=".popover_trigger"
             @show="getTargetGroupFilters()">

    <div class="popover_trigger">      
      <i class="icon top fas fa-users-cog"/>     
    </div>

    <template slot="popover">
      <CCard  class="mb-0">
        <CCardBody>
          <CRow v-if="targetGroupFiltersLoading === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="item_tags">
                <b-tag v-for="(filter, index) in targetGroupFilters" v-bind:key="index" class="mt-1 info">
                  <span v-if="filtersWithValueLabel.includes(filter.group_filter_type_tag)">
                    <b>{{$t('filter.' + filter.group_filter_type_tag)}}</b> {{$t('condition.' + filter.group_filter_condition_tag).toLowerCase()}} <b>{{filter.group_filter_value_label}}</b>
                  </span>
                  <span v-else-if="filtersWithFilterValue.includes(filter.group_filter_type_tag)">
                    <b>{{$t('filter.' + filter.group_filter_type_tag)}}</b> {{$t('condition.' + filter.group_filter_condition_tag).toLowerCase()}} <b>{{$t('filter_value.' + filter.group_filter_type_value_tag).toLowerCase()}}</b>
                  </span>                  
                  <span v-else>
                    <b>{{$t('filter.' + filter.group_filter_type_tag)}}</b> {{$t('condition.' + filter.group_filter_condition_tag).toLowerCase()}} <b>{{filter.group_filter_value}}</b>
                  </span>
                </b-tag>
              </div>
            </CCol>
          </CRow>                    
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>
import axios from 'axios';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'targetGroupFiltersPopover',
  props: ['targetGroupsType', 'targetGroupsIdExternal', 'popoverPlacement'],
  components: {
    loadingSpinner
  },  
  watch: {
    $props: {
      handler() {
        if(this.targetGroupsType === 'loyalty moment') {
          this.detailsPath = 'loyalty/moment';
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      detailsPath: null,
      targetGroupFilters: [],
      targetGroupFiltersLoading: false,
      targetGroupFiltersLoaded: false,
      filtersWithValueLabel: ['user', 'department', 'team', 'target_group'],
      filtersWithFilterValue: ['date_started', 'date_left']
    }
  },
  methods: {
    getTargetGroupFilters() {
      if(this.targetGroupFiltersLoaded === false) {
        // Show the loader
        this.targetGroupFiltersLoading = true;
        // Get the target groups
        axios.get(process.env.VUE_APP_API_URL + '/v1/' + this.detailsPath + '/' + this.targetGroupsIdExternal + '/filters')
        .then(res => {
          this.targetGroupFilters = res.data.data;
          
          setTimeout(function() {
            // Hide the loader
            this.targetGroupFiltersLoading = false;
            // Update the profile Loaded value
            this.targetGroupFiltersLoaded = true;
          }.bind(this), 250);           
        })
        .catch(err => {
          console.error(err); 
        });
      }
    }
  }    
}
</script>