<template>
  <CCard class="subject navigation_card pointer fit-content" @click="showMomentsTemplates();">
    <CCardBody class="p-2">
      <div class="d-flex align-items-center">
        <div class="mr-2">          
          <img v-if="imageId" :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/global/' + imageId + '/' + clientToken"/>
          <img v-else :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/envelope.png/' + clientToken"/>
        </div>
        <div class="flex-grow-1">
          <span><b>{{name}}</b></span>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'MomentSubjectCard',
  props: ['name', 'imageId', 'tags'],
  watch: {
    $props: {
      handler() {
        if(this.tags) this.preSelectTemplateTags = this.tags;        
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      preSelectTemplateTags: null
    }
  },
  methods: {
    showMomentsTemplates() {
      // Save the preSelectTemplateTags item to localStorage if the preSelectTemplateTags are available
      if(this.preSelectTemplateTags) localStorage.setItem('preSelectedTemplateTags', JSON.stringify(this.preSelectTemplateTags));
      // Navigate to Moments templates page
      this.$router.push({path: '/loyalty/moments/templates'});
    },
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
  }
}
</script>
