<template>
  <div>  
    <CCard class="mb-0 events moments">
      <CCardHeader v-if="showHeader" class="pb-0">
        <CRow>
          <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
            <span>{{$t('loyalty.Moments')}}</span>
          </CCol>
          <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
            <div class="mt-1 mt-xl-0 d-flex align-items-start justify-content-xl-end">          
              <CButton v-if="checkPermission('loyalty.moments.templates') && (checkPermission('loyalty.moments.edittemplate') || checkPermission('loyalty.moments.editcustomtemplate'))" color="primary" class="orange m-0" @click="openLoyaltyMomentTemplatesPage()">
                <span><i class="fas fa-pen mr-1"/>{{$t('loyalty.Edit_moment_templates')}}</span>
              </CButton>
            </div>
          </CCol>          
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <div v-if="momentsLoading === true">           
          <CRow>
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
        </div>
        <div v-else>
          <div v-if="(checkPermission('loyalty.moments.send') && company.payment_status === true) || checkPermission('loyalty.configure')" class="d-flex p-2_5">
            <div v-if="checkPermission('loyalty.moments.send') && company.payment_status === true" class="send_moment_button mr-2_5">
              <CCard class="navigation_card pointer fit-content m-0" @click="openLoyaltyMomentWizard();">
                <CCardBody class="p-2">
                  <div class="d-flex align-items-center">
                    <div v-if="apiBaseUrl && clientToken" class="mr-2">                           
                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/envelope.png/' + clientToken"/>
                    </div>
                    <div class="flex-grow-1">
                      <span><b>{{$t('loyalty.Send_a_moment')}}</b></span>
                    </div>
                  </div>
                </CCardBody>
              </CCard>            
            </div>
            <div v-if="checkPermission('loyalty.configure') && modules.auto_moments === 1" class="send_moment_button">
              <CCard class="navigation_card pointer fit-content m-0" @click="openLoyaltyAutoMomentsPage();">
                <CCardBody class="p-2">
                  <div class="d-flex align-items-center">
                    <div v-if="apiBaseUrl && clientToken" class="mr-2">
                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/common/auto_moment.png/' + clientToken"/>
                    </div>
                    <div class="flex-grow-1">
                      <span><b>{{$t('common.Auto_moments')}}</b></span>
                    </div>
                  </div>
                </CCardBody>
              </CCard>            
            </div>          
          </div>
          <hr class="mt-0" v-bind:class="{'mb-0' : moments.length === 0, 'mb-2_5' : moments.length > 0}">           
          <CRow class="moment_list">
            <CCol cols="12" lg="12" md="12" class="pt-0 pb-0">
              <b-table class="data_table"
                        :data="moments"
                        :striped="true"
                        :hoverable="true"
                        :mobile-cards="true"
                        :paginated="isPaginated"
                        :per-page="itemsPerPage"
                        :current-page.sync="currentPage"
                        :pagination-simple="isPaginationSimple"
                        :pagination-position="paginationPosition">
                
                <template slot-scope="props">
                  <b-table-column field="name" :label="$t('common.Name')" :searchable="searchEnabled" :sortable="true" width="20%">
                    <b>{{props.row.name}}</b>
                  </b-table-column>
                  <b-table-column field="sender" :label="$t('common.Created_by')" :searchable="searchEnabled" :sortable="true" width="20%">
                    <div class="d-flex align-items-center">
                      <userProfilePopover v-if="props.row.user_is_system_account !== 'Y'" 
                                          ref="userProfilePopover"
                                          mode="user" 
                                          :userName="props.row.sender"
                                          :userProfileImage="props.row.sender_profile_image" 
                                          :userIdExternal="props.row.sender_user_id_external"
                                          :senderIdExternal="null"
                                          popoverPlacement="right"
                                          class="mr-2">
                      </userProfilePopover>
                      <b>{{props.row.sender}}</b>
                    </div>
                  </b-table-column>                    
                  <b-table-column field="total_recipients" :label="$t('communications.Total_recipients')" :searchable="false" :sortable="true" width="12.5%">
                    <span v-if="props.row.total_recipients">{{props.row.total_recipients}} {{props.row.total_recipients !== 1 ? $t('common.recipients') : $t('common.recipient')}}</span>
                    <span v-else>-</span>
                  </b-table-column>
                  <b-table-column field="total_filters" :label="$t('groups.Filters_used')" :searchable="false" :sortable="true" width="12.5%">
                    <div v-if="props.row.total_filters" class="d-flex align-items-center">
                      <span class="mr-2">{{props.row.total_filters}} {{props.row.total_filters !== 1 ? $t('common.filters') : $t('common.filter')}}</span>                  
                      <targetGroupFiltersPopover targetGroupsType="loyalty moment" :targetGroupsIdExternal="props.row.loyalty_moment_id_external" popoverPlacement="left"/>
                    </div>
                    <span v-else>-</span>
                  </b-table-column>                    
                  <b-table-column field="points" :label="$t('communications.Points_granted')" :searchable="false" :sortable="true" width="12.5%">
                    <div v-if="props.row.points_granted === 1" class="d-flex align-items-center credits">
                      <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
                      <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
                      <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
                      <span>{{props.row.points}} {{props.row.points !== 1 ? $t('common.points') : $t('common.point')}}</span>
                    </div>                    
                    <span v-else>-</span>
                  </b-table-column>
                  <b-table-column field="sent" :label="$t('communications.Sent_scheduled')" class="sent_scheduled" :searchable="false" :sortable="true" width="15%">
                    <div v-if="props.row.sent" class="d-flex align-items-center date">                    
                      <i class="fa-solid fa-check"></i>
                      <span v-if="props.row.sent">{{props.row.sent | moment("DD-MM-YYYY HH:mm")}}</span>
                    </div>
                    <div v-else-if="props.row.scheduled === 1" class="d-flex align-items-center date">
                      <i class="fa-solid fa-calendar-day"></i>
                      <span v-if="props.row.scheduled_for">{{props.row.scheduled_for | moment("DD-MM-YYYY HH:mm")}}</span>
                    </div>                  
                    <span v-else>-</span>
                  </b-table-column>
                  <b-table-column field="actions" label="" width="10%" :visible="checkPermission('loyalty.moments.details') || checkPermission('loyalty.moments.edit') || checkPermission('loyalty.moments.delete')">
                    <div class="d-flex justify-content-lg-end">
                      <CDropdown v-if="checkPermission('loyalty.moments.details') || checkPermission('loyalty.moments.edit') || checkPermission('loyalty.moments.delete')"
                                color="primary"
                                toggler-text=""
                                :caret="false"
                                class="ml-2 table_actions_dropdown">
                        <div slot="toggler-content">
                          <span class="d-flex align-items-center">
                            {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                          </span>
                        </div>

                        <CDropdownItem v-if="checkPermission('loyalty.moments.details')">
                          <div class="d-flex align-items-center" @click="openLoyaltyMomentDetails(props.row.loyalty_moment_id_external)">
                            <div class="post_action_icon text-center mr-1">
                              <i class="fas fa-chevron-right"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('common.Show_details')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>
                        <CDropdownItem v-if="props.row.moment_scheduled && props.row.moment_editable && checkPermission('loyalty.moments.edit')">
                          <div class="d-flex align-items-center" @click="openLoyaltyMomentEditPage(props.row.loyalty_moment_id_external)">
                            <div class="post_action_icon text-center mr-1">
                              <i class="fas fa-pen"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('loyalty.Edit_moment')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>
                        <CDropdownItem v-if="props.row.moment_scheduled && props.row.moment_deletable && checkPermission('loyalty.moments.delete')">
                          <div class="d-flex align-items-center" @click="confirmModal = true; confirmModalData = props.row;">
                            <div class="post_action_icon text-center mr-1">
                              <i class="fas fa-trash"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('loyalty.Delete_moment')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>                      
                      </CDropdown>
                    </div>
                  </b-table-column>                    
                </template>
                <template slot="empty">
                  <div class="text-center">
                    <span>{{$t('loyalty.No_moments_available')}}</span>
                  </div>
                </template>                
              </b-table>  
            </CCol>                  
          </CRow>
        </div>
      </CCardBody>
    </CCard>

    <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('loyalty.Remove_scheduled_loyalty_moment', { moment_name : confirmModalData.name})}} ({{confirmModalData.scheduled_for | moment("DD-MM-YYYY HH:mm")}})
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0" v-html="$t('loyalty.Are_you_sure_to_delete_scheduled_loyalty_moment')"></p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="deleteLoyaltyMoment(confirmModalData.loyalty_moment_id_external);"><i class="fas fa-trash mr-1"/>{{$t('Delete')}}</CButton>
          <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import targetGroupFiltersPopover from '@/components/common/targetGroupFiltersPopover.vue'
import loyaltyMomentSubjectCard from '@/components/loyalty/loyaltyMomentSubjectCard.vue'

export default {
  name: 'loyaltyMoments',
  props: ['platformPermissions', 'modules', 'showHeader', 'itemsPerPage'],
  components: {
    loadingSpinner,
    userProfilePopover,
    targetGroupFiltersPopover,
    loyaltyMomentSubjectCard
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      company: {},
      moments: [],      
      momentsLoading: false,
      momentsLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      searchEnabled: true,
      hideDelivered: false,
      confirmModal: false,
      confirmModalData: {}      
    }
  },
  methods: {    
    getMoments() {
      // Start the loader
      if(this.momentsLoaded === false) this.momentsLoading = true;
      // Get the Moments
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moments')
      .then(res => {              
        this.moments = res.data.data;
        // Enable the pagination if necessary
        (this.moments.length > this.itemsPerPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.momentsLoading = false;
        // Upda
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openLoyaltyMomentWizard() {
      this.$router.push({path: '/loyalty/moments/send'});
    },
    openLoyaltyMomentDetails(momentIdExternal) {
      this.$router.push({path: '/loyalty/moment/' + momentIdExternal});
    },
    openLoyaltyMomentEditPage(momentIdExternal) {
      this.$router.push({path: '/loyalty/moment/' + momentIdExternal + '/edit'});
    },        
    openLoyaltyAutoMomentsPage() {
      this.$router.push({path: '/loyalty/auto-moments'});
    }, 
    openLoyaltyMomentTemplatesPage() {
      this.$router.push({path: '/loyalty/moments/templates'});
    },
    deleteLoyaltyMoment(momentIdExternal) {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/' + momentIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('loyalty.Scheduled_loyalty_momen_deleted'), type: 'is-success', duration: 2000 });
        // Close the modal
        this.confirmModal = false;
        // Reload the Loyalty Moments
        this.getMoments();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }                
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
        
    this.getCompanyDetails();
    this.getMoments();
  }
}
</script>